/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Grid, jsx, Styled } from "theme-ui"

import Variant from "@components/Variant"
import NewsLayout from "@layout/NewsLayout"

import Categories from "./Categories"
import FeaturedImage from "./FeaturedImage"

export const fragment = graphql`
  fragment Post on ContentfulBlogPost {
    categories
    content {
      content
    }
    featuredImage {
      ...ImageFields
    }
    id
    postTitle
  }
`

export default function Post({
  categories,
  content,
  featuredImage,
  postTitle,
}) {
  return (
    <Variant
      sx={{ position: "relative" }}
      variants={["container", "containerSpacing"]}
    >
      {featuredImage?.url && <FeaturedImage image={featuredImage} />}

      <NewsLayout variants={["container"]}>
        <Grid as="article">
          <Categories categories={categories} />
          <Styled.h2 sx={{ mb: 7 }}>{postTitle}</Styled.h2>
          <div
            dangerouslySetInnerHTML={{ __html: content?.content }}
            sx={{
              h1: {
                variant: "heading",
                fontSize: [7, null, 8],
                lineHeight: "0.9",
                mb: 5,
                mt: 12,
              },
              h2: {
                variant: "heading",
                fontSize: [6, 7],
                lineHeight: "1",
                mb: 5,
                mt: 12,
              },
              h3: {
                variant: "heading",
                fontSize: [4, 5],
                lineHeight: "1.3",
                mb: 5,
                mt: 12,
              },
              h4: {
                variant: "heading",
                fontSize: [2, 6],
                lineHeight: "1.2",
                mb: 5,
                mt: 12,
              },
              "> img": {
                my: 12,
              },
              img: {
                borderRadius: "20px",
                height: "auto",
                width: "100%",
              },
              li: {
                variant: "richtext.li",
              },
              a: {
                color: "primary",
                fontWeight: "bold",
                lineHeight: "24px",
              },
              p: {
                variant: "reset",
                color: "grey.dark",
                fontSize: [1, 3],
                lineHeight: "28px",

                "& + p": {
                  mt: 5,
                },
                ":first-of-type": {
                  variant: "text.large",
                  color: "grey.dark",
                  fontSize: [2, 4],
                  lineHeight: "28px",
                },
              },
              ":not(erryting)": {
                variant: "reset",
                color: "grey.dark",
                fontSize: [1, 3],
                lineHeight: "28px",
              },
            }}
          />
        </Grid>
      </NewsLayout>
    </Variant>
  )
}

Post.defaultProps = {
  categories: null,
  featuredImage: null,
}

Post.propTypes = {
  categories: PropTypes.array,
  content: PropTypes.object.isRequired,
  featuredImage: PropTypes.object,
  postTitle: PropTypes.string.isRequired,
}
