import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"

import Post from "@components/Post"
import Seo from "@components/Seo"

export default function PostTemplate({ data: { post } }) {
  return (
    <Fragment>
      {/* <Seo seo={post.seotags} /> */}
      <Post {...post} />
    </Fragment>
  )
}

export const query = graphql`
  query getPost($node_id: String!) {
    post: contentfulBlogPost(id: { eq: $node_id }) {
      ...Post
    }
  }
`

PostTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
}
