/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function FeaturedImage({ image }) {
  if (image) {
    return (
      <div
        sx={{
          backgroundImage: `url(${image?.file?.url})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "10px",
          height: [150, 250, 600],
          mb: 12,
          overflow: "hidden",
          objectFit: "contain",
          objectPosition: "center",
          width: "100%",
        }}
      />
    )
  }

  return null
}

FeaturedImage.defaultProps = {
  image: null,
}

FeaturedImage.propTypes = {
  image: PropTypes.object,
}
