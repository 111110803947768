/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, jsx } from "theme-ui"

export default function Categories({ categories }) {
  if (Array.isArray(categories) && categories.length) {
    const isMoreItemsThanOne = categories.length > 1
    return (
      <Flex
        sx={{
          flexWrap: "wrap",
          mb: "14px",
          wordBreak: "break-word",
        }}
      >
        {categories.map(category => (
          <Category key={category} length={isMoreItemsThanOne}>
            {category}
          </Category>
        ))}
      </Flex>
    )
  }

  return null
}

Categories.defaultProps = {
  categories: null,
}

Categories.propTypes = {
  categories: PropTypes.array,
}

function Category({ children, length }) {
  return (
    <span
      sx={{
        color: "primary",
        display: "inline-block",
        fontSize: 3,
        fontWeight: "bold",
        mb: 4,
        mr: length ? 8 : null,
        position: "relative",

        "::after": length
          ? {
              color: "text",
              content: '"|"',
              right: "-16px",
              position: "absolute",
              top: "0px",
            }
          : {},
        ":last-of-type": {
          "::after": {
            display: "none",
          },
        },
      }}
    >
      {children}
    </span>
  )
}

Category.propTypes = {
  children: PropTypes.node.isRequired,
  length: PropTypes.bool.isRequired,
}
