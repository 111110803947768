/** @jsx jsx */
import PropTypes from "prop-types"
import { useMemo } from "react"
import { Flex, Grid, jsx } from "theme-ui"

import themeVariants from "../gatsby-plugin-theme-ui/variants"

function getVariants(variant, variants) {
  if (variant) {
    return themeVariants[variant]
  }

  if (Array.isArray(variants) && variants.length) {
    return variants
      .map(v => themeVariants[v])
      .reduce((acc, val) => ({ ...acc, ...val }), {})
  }
  return {}
}

export default function Variant({
  as,
  children,
  d,
  variant,
  variants,
  ...props
}) {
  const Component = useMemo(() => {
    if (d) {
      if (d.toLowerCase() === "flex") {
        return Flex.withComponent(as)
      }
      if (d.toLowerCase() === "grid") {
        const G = Grid
        /* eslint-disable */
        G.defaultProps = G.defaultProps || {}
        /* eslint-enable */
        G.defaultProps.as = as
        return G
      }
    }
    return as
  }, [as, d])
  const styles = useMemo(() => getVariants(variant, variants), [
    variant,
    variants,
  ])

  return (
    <Component sx={styles} {...props}>
      {children}
    </Component>
  )
}

Variant.defaultProps = {
  as: "div",
  d: undefined,
  variant: undefined,
  variants: null,
}

Variant.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  d: PropTypes.string,
  variant: PropTypes.string,
  variants: PropTypes.array,
}
